@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.accent-text {
  color: slategray;
  font-style: italic;
  font-size: 80%;
}

.search-action-disabled, .search-action-disabled:hover {
  text-decoration: line-through;
  color: lightgray !important;
}

.huge {
  font-size: 40px;
}

.panel-green {
  border-color: #5cb85c;
}

.panel-green .panel-heading {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #fff;
}

.panel-green a {
  color: #5cb85c;
}

.panel-green a:hover {
  color: #3d8b3d;
}

.panel-red {
  border-color: #d9534f;
}

.panel-red .panel-heading {
  background-color: #d9534f;
  border-color: #d9534f;
  color: #fff;
}

.panel-red a {
  color: #d9534f;
}

.panel-red a:hover {
  color: #b52b27;
}

.panel-yellow {
  border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  color: #fff;
}

.panel-yellow a {
  color: #f0ad4e;
}

.panel-yellow a:hover {
  color: #df8a13;
}

.panel-purple {
  border-color: #D050D0;
}

.panel-purple .panel-heading {
  background-color: #D050D0;
  border-color: #D050D0;
  color: #fff;
}

.panel-purple a {
  color: #D050D0;
}

.panel-purple a:hover {
  color: #B232B2;
}

.panel-gray {
  border-color: lightslategray;
}

.panel-gray .panel-heading {
  background-color: lightslategray;
  border-color: lightslategray;
  color: #fff;
}

.panel-gray a {
  color: lightslategray;
}

.panel-gray a:hover {
  color: #8c8c8c;
}

.table-hover > tbody > tr:hover {
  background-color: #E7E7E7;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.fa-dress {
  cursor: pointer;
}

.help-block-xs {
  font-size: 8px;
  margin-top: 0;
  margin-bottom: 1px;
}

.help-block-sm {
  font-size: 9px;
  margin-top: 0;
  margin-bottom: 1px;
}

hr.short {
  margin-bottom: 5px;
  margin-top: 5px;
}

.impact-collapse-panel .panel, .impact-collapse-panel .panel-body {
  padding: 0;
  border: none;
  box-shadow: none;
}

.fa.fa-prospect {
  color: #337ab7;
}

.fa.fa-contact {
  color: #f0ad4e;
}

.fa.fa-call {
  color: #d050d0;
}

.fa-inverse {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

#ajax_tabs .container-fluid {
  margin: 0;
  border: none;
  background-color: #ffffff;
}

#ajax_tabs .navbar {
  margin-bottom: 0;
}

#ajax_tabs ul.pagination {
  margin: 0;
}

#ajax_tabs .navbar-default {
  border: none;
  background-color: #ffffff;
}

// Pagination in a vertical wrapper

.vertical {
  padding: 6px;
}

.vertical .pagination > li > a,
.vertical .pagination > li > span {
  padding: 6px 12px;
}

.vertical .pagination li a {
  width: 100%;
  text-align: center;
}

.vertical .pagination > li:first-child > a,
.vertical .pagination > li:first-child > span {
  border-radius: 4px 4px 0 0;
  width: 100%;
  text-align: center;
  margin-left: -1px;
}

.vertical .pagination > li:last-child > a,
.vertical .pagination > li:last-child > span {
  border-radius: 0 0 4px 4px;
  width: 100%;
  text-align: center;
  margin-left: -1px;
}

.vertical .pagination > .active > a,
.vertical .pagination > .active > a:hover,
.vertical .pagination > .active > a:focus,
.vertical .pagination > .active > span,
.vertical .pagination > .active > span:hover,
.vertical .pagination > .active > span:focus {
  width: 100%;
  text-align: center;
}

.vertical .pagination > .disabled > span,
.vertical .pagination > .disabled > span:hover,
.vertical .pagination > .disabled > span:focus,
.vertical .pagination > .disabled > a,
.vertical .pagination > .disabled > a:hover,
.vertical .pagination > .disabled > a:focus {
  width: 100%;
  text-align: center;
}

#inquiries td:not(.wrap) {
  white-space: nowrap;
}

#inquiries .wrap {
  white-space: normal !important;
}

@media(max-width: 767px) {
  .pagination li:not(:first-child):not(:last-child) {
    display: none;
  }
}

.input-group-addon.primary {
  background-color: #337ab7;
  border-color: #2e6da4;
  color: #fff;
}

.input-group-addon.primary:hover {
  background-color: #286090;
  border-color: #204d70;
}

.input-group-addon.info {
  background-color: #5bc0de;
  border-color: #46b8da;
  color: #fff;
}

.input-group-addon.info:hover {
  background-color: #31b0d5;
  border-color: #269abc;
}

.input-group-addon.danger {
  background-color: #d9534f;
  border-color: #d43f3a;
  color: #fff;
}

.input-group-addon.danger:hover {
  background-color: #c9302c;
  border-color: #ac2925;
}

.input-group-addon.warning {
  background-color: #f0ad4e;
  border-color: #eea236;
  color: #fff;
}

.input-group-addon.warning:hover {
  background-color: #ec971f;
  border-color: #d58512;
}

._btn {
  cursor: pointer;
}

table.table-fit {
  width: auto !important;
}

table.table-borderless,
table .table-borderless th,
table.table-borderless td {
  border: none !important;
}

.editable {
  cursor: pointer;
}

.top-buffer-lg {
  margin-top: 20px !important;
}

.top-buffer {
  margin-top: 10px !important;
}

.top-buffer-sm {
  margin-top: 5px !important;
}

.top-buffer-xs {
  margin-top: 2px !important;
}

.top-buffer-none {
  margin-top: 0 !important;
}

.bot-buffer-lg {
  margin-bottom: 20px !important;
}

.bot-buffer {
  margin-bottom: 10px !important;
}

.bot-buffer-sm {
  margin-bottom: 5px !important;
}

.bot-buffer-xs {
  margin-bottom: 2px !important;
}

.bot-buffer-none {
  margin-bottom: 0 !important;
}

.btn-xsm, .btn-group-xsm > .btn {
  border-radius: 2px;
  font-size: 11px;
  line-height: 1.5;
  padding: 0px 4px;
}

.panel-heading.panel-heading-sm {
  padding: 5px 8px;
}

.spinner-setup {
  margin: 10px;
}

.row.closable .clickable {
  cursor: pointer;
}

.row.closable .clickable:hover {
  opacity: .7;
}

.row.closable .panel-heading span {
  margin-top: -20px;
  font-size: 15px;
}

.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

.material-switch > label::before {
  background: #d9534f;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch > label::after {
  background: #d9534f;
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}

.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.list-group.lg-small {
  margin-bottom: 2px;
  font-size: 85%;
}

.list-group-item.lg-small {
  padding: 7px 8px;
}

span.required:before {
  content: "*";
  color: red;
  font-size: 110%;
}

div.no-lr-pad {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 769px) {
  input.input-date {
    width: 90px !important;
    text-align: center;
  }
  input.input-narrow {
    width: 40px !important;
  }
  input.input-wide, select.input-wide {
    width: 100px !important;
  }
  input.input-phone {
    width: 125px !important;
    text-align: center;
  }

  input.input-wider, input.input-datetime, select.input-wider {
    width: 150px !important;
  }
  input.input-wider-plus, select.input-wider-plus {
    width: 250px !important;
  }
  input.input-widest, select.input-widest {
    width: 300px !important;
  }
  input.input-email, select.input-email {
    width: 350px !important;
  }
  input.input-address, select.input-address {
    width: 400px !important;
  }

  input.input-zip, select.input-zip {
    width: 55px !important;
    text-align: center;
  }

  input.input-zip4, select.input-zip4 {
    width: 50px !important;
    text-align: center;
  }
}

td.skinny-nowrap, th.skinny-nowrap {
  width: 1%;
  white-space: nowrap;
}

.btn.pill {
  padding: 0 5px !important;
  border-radius: 15px !important;
  color: #ffffff !important;
  font-size: 12px !important;
}

.btn.btn-bump-r {
  margin-right: 3px;
}

.btn.btn-bump-l {
  margin-left: 3px;
}

.help-block-left {
  float: left;
}

textarea.short-text-area {
  height: 100px;
}

div.form-group-depad .form-group {
  margin-bottom: 5px;
}

form .list-group-item {
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}

.edit-prospect-wrapper form .form-group {
  margin-bottom: 10px !important;
}

.fa.fa-secondary {
  font-size: 70%;
}

.field-ident {
  color: dimgray;
  font-style: italic;
  font-size: 80%;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.squish-table {
  margin-bottom: 0;
}

.squish-table tr > td:not(.skip-row-format),
.squish-table tr > th:not(.skip-row-format) {
  width: 1% !important;
  white-space: nowrap !important;
}

div.selectize-mimic {
  font-size: 80%;
  display: inline-block;
  padding: 2px 6px;
  color: #ffffff;
  background: #1da7ee;
  border: 1px solid #0073bb;
  text-shadow: 0 1px 0 rgba(0, 51, 83, 0.3);
  background-image: -moz-linear-gradient(top, #1da7ee, #178ee9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#1da7ee), to(#178ee9));
  background-image: -webkit-linear-gradient(top, #1da7ee, #178ee9);
  background-image: -o-linear-gradient(top, #1da7ee, #178ee9);
  background-image: linear-gradient(to bottom, #1da7ee, #178ee9);
  background-repeat: repeat-x;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1da7ee', endColorstr='#ff178ee9', GradientType=0);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), inset 0 1px rgba(255, 255, 255, 0.03);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), inset 0 1px rgba(255, 255, 255, 0.03);
}

.badge {
  padding: 4px 6px;
  font-size: 12.025px;
  font-weight: bold;
  white-space: nowrap;
  color: #ffffff;
  background-color: #999999;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.badge:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.badge-danger {
  background-color: #b94a48;
}

.badge-danger:hover {
  background-color: #953b39;
}

.badge-warning {
  background-color: #f89406;
}

.badge-warning:hover {
  background-color: #c67605;
}

.badge-success {
  background-color: #468847;
}

.badge-success:hover {
  background-color: #356635;
}

.badge-info {
  background-color: #3a87ad;
}

.badge-info:hover {
  background-color: #2d6987;
}

.badge-inverse {
  background-color: #333333;
}

.badge-inverse:hover {
  background-color: #1a1a1a;
}

.case-lists td h6 {
  margin: 0 auto 0 auto;
  color: #606060;
}

.case-lists td ul {
  padding-left: 5px;
  margin-bottom: 0;
  color: darkblue;
  font-size: 75%
}

/* Icon pulse */
.fa-pulse {
  display: inline-block;
  -moz-animation: pulse 2s infinite linear;
  -o-animation: pulse 2s infinite linear;
  -webkit-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
}

@-webkit-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-ms-keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.fa-bounce {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 1s infinite linear;
  -o-animation: bounce 1s infinite linear;
  -webkit-animation: bounce 1s infinite linear;
  animation: bounce 1s infinite linear;
}

@-webkit-keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
@-moz-keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
@-o-keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
@-ms-keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
@keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}

@import "_bootstrap-datetimepicker.scss";
@import "_selectize.scss";
@import "_fullcalendar.scss";
@import "_fileinput.scss";